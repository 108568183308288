import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/account/register'),
  },
  {
    path: '/language',
    name: 'my',
    component: () => import('@/views/account/language'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/account/web'),
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/account/forget'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/hall',
    name: 'hall',
    component: () => import('@/views/hall/hall'),
  },
  {
    path: '/hall/sell',
    name: 'hall',
    component: () => import('@/views/hall/sell'),
  },
  {
    path: '/hall/buy',
    name: 'hall',
    component: () => import('@/views/hall/buy'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order'),
  },
  {
    path: '/order/details',
    name: 'order',
    component: () => import('@/views/order/details'),
  },
  {
    path: '/order/details2',
    name: 'order2',
    component: () => import('@/views/order/details2'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/index'),
  },
  {
    path: '/my/userinfo',
    name: 'my',
    component: () => import('@/views/my/userinfo'),
  },
  {
    path: '/my/edit-nickname',
    name: 'my',
    component: () => import('@/views/my/edit-nickname'),
  },
  {
    path: '/my/edit-password',
    name: 'my',
    component: () => import('@/views/my/edit-password'),
  },
  {
    path: '/my/asset',
    name: 'my',
    component: () => import('@/views/my/asset'),
  },
  {
    path: '/my/team',
    name: 'my',
    component: () => import('@/views/my/team'),
  },
  {
    path: '/my/invite',
    name: 'my',
    component: () => import('@/views/my/invite'),
  },
  {
    path: '/my/accountBind',
    name: 'my',
    component: () => import('@/views/my/accountBind'),
  },
  {
    path: '/my/accountDetail',
    name: 'my',
    component: () => import('@/views/my/accountDetail'),
  },
  {
    path: '/my/about',
    name: 'my',
    component: () => import('@/views/my/about'),
  },
  {
    path: '/home/novice',
    name: 'home',
    component: () => import('@/views/home/novice'),
  },
  {
    path: '/home/noviceDetail',
    name: 'home',
    component: () => import('@/views/home/noviceDetail'),
  },
  {
    path: '/home/messageDetail',
    name: 'home',
    component: () => import('@/views/home/messageDetail'),
  },
  {
    path: '/home/message',
    name: 'home',
    component: () => import('@/views/home/message'),
  },
  {
    path: '/assets/recharge',
    name: 'home',
    component: () => import('@/views/assets/recharge'),
  },
  {
    path: '/assets/withdraw',
    name: 'home',
    component: () => import('@/views/assets/withdraw'),
  },

]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
